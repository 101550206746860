import React from "react"
import { Row, Col, Container, Button } from "react-bootstrap"
import * as styles from "../../styles/components/About/about.module.scss"
import Title from "../title"
import { graphql, useStaticQuery } from "gatsby"
import { useIntl, Link } from "gatsby-plugin-react-intl"

export default function About() {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            location
            slug
          }
        }
      }
    }
  `)

  const locations = data.allContentfulLocation.edges

  return (
    <>
      <Title title={intl.formatMessage({ id: "about" })} />
      <Container>
        <Row className="mb-5">
          <Col md={8} className={`mx-auto my-5  ${styles.content}`}>
            <h1 className={`my-5 ${styles.title}`}>
              Kids &amp; Teens Medical Group
            </h1>

            <p className={`${styles.sub} mb-5`}>
              {" "}
              {intl.formatMessage({ id: "pediatricsIn" })}{" "}
              {locations
                .sort((a, b) => (a.node.location > b.node.location ? 1 : -1))
                .map(({ node }, idx) => (
                  <Link
                    key={idx}
                    to={`/locations/${node.slug}`}
                    className={styles.location}
                  >{`${idx === locations.length - 1 ? "and " : ""}${
                    node.location
                  }${idx !== locations.length - 1 ? ", " : "."}`}</Link>
                ))}
            </p>

            <div className="mt-5">
              <p>
                {intl.formatMessage({ id: "aboutUs0" })}{" "}
                {locations
                  .sort((a, b) => (a.node.location > b.node.location ? 1 : -1))
                  .map(({ node }, idx) => (
                    <Link
                      key={idx}
                      to={`/locations/${node.slug}`}
                      className={styles.location}
                    >{`${idx === locations.length - 1 ? "and " : " "}${
                      node.location
                    }, `}</Link>
                  ))}{" "}
                {intl.formatMessage({ id: "aboutUs1" })}{" "}
              </p>
              <p>{intl.formatMessage({ id: "aboutUs2" })} </p>
              <p>{intl.formatMessage({ id: "aboutUs3" })} </p>

              <p>{intl.formatMessage({ id: "aboutUs4" })} </p>

              <p>{intl.formatMessage({ id: "aboutUs5" })} </p>

              <p>{intl.formatMessage({ id: "aboutUs6" })} </p>

              <p>{intl.formatMessage({ id: "aboutUs7" })} </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
