import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import About from "../components/About/about"

const IndexPage = () => (
  <Layout>
    <Seo title="About" description="Meet the providers and team at Kids &amp; Teens Medical Group, proudly serving the  communities for a variety of pediatric services." />
    <About
    />
  </Layout>
)

export default IndexPage
